import { projectAuth, projectFirestore } from '@/firebase/config';
import { collection, query, where, getDocs, onSnapshot, getFirestore } from "firebase/firestore";

import FileManager from "../views/file-manager/Main";
import { mapGetters } from 'vuex'
import useCollection from '@/composables/useCollection';
import getUser from '@/composables/getUser'
import { useRouter } from 'vue-router';


const { error, addDoc, addDocWithGenId, getCollection, isPending, newDoc, documents: applications } = useCollection('required-form');
const { updateDocWithGenId } = useCollection('profileDetails');
const {user} = getUser();

const state = () => {
  return {
      lastUpdated: null,
      updatedRecently: false,
      watsonBusinessStrength: 'PENDING',
      bizInfo: {
          companyName: '',
          dbaName: '',
          address1: '',
          address2: '',
          city:'',
          state:'AL',
          zipcode:'',
          taxId:'',
          duns:'',
          businessContactNumber:'',
          email:'',
          website:'',
          entityType:'Incorporation',
          industryType:'Automobiles and Components',
          yrsInBusiness:0,
        },
      ownerInfo: {
          firstName: '',
          middleName:'',
          lastName:'',
          dob:'',
          ssn:'',
          ownerInfoAddress1: '',
          ownerInfoAddress2: '',
          ownerInfoCity:'',
          ownerInfoState:'AL',
          ownerInfoZipcode:'',
          phone:'',
          creditScore:'',
          ownerInfoEmail:'',
          gender:'Not Disclosed',
          ethnicity:'Not Disclosed',
          race:'Not Disclosed',
          veteran:'Not Disclosed',
          indictment:false,
          arrested:false,
          convicted:false,
          citizen:false,
          suspendFederalAgency:false,
          childSupport:false,
      },
      contingentLiabilities: {
          otherIncome: 0,
          legalClaimsFederal:0,
          incomeTax:0,
          otherSpecialDebt:0
      },
      sourceIncome: {
          salary: 0,
          netInvestIncome:0,
          realEstateIncome:0,
          lifeInsuranceLoan:0,
          totalNetIncome:0,
      }
  }
}


// getters
const getters = {
}



// actions
const actions = {
   async submitFirebase (context) {
      console.log("Show Me");
//     console.log(JSON.stringify(user));
      await addDocWithGenId(this.state.requiredForm, user._value.email);
      await updateDocWithGenId(user._value.uid, "companyName",this.state.requiredForm.bizInfo.companyName);
   },

    fetchItems({ commit }) {
      const documentRef = projectFirestore.collection('required-form').doc('jtolson@mytechconcepts.com')
        .onSnapshot(snap => {
          let results = []
            if (snap.exists) {
              commit('setRequiredFormStateObj', snap.data());
            }
          });


      // projectFirestore.collection('required-form').onSnapshot(snapshot => {
      //   const listDocs = [];
      //   snapshot.forEach(doc => {
      //     listDocs.push({ id: doc.id, ...doc.data() });
      //   });
      //   commit('setRequiredFormStateObj', listDocs);
      // });

  },

}

// mutations
const mutations = {
  setWatsonBusinessStrength(state, value) {
    this.state.requiredForm.watsonBusinessStrength = value;
  },
  setLastUpdated (state, value) {
    this.state.requiredForm.lastUpdated = value
  },
  setUpdatedRecently (state, value) {
    this.state.requiredForm.updatedRecently = value
  },
  setCompanyName(state, value) {
       this.state.requiredForm.bizInfo.companyName = value;
  },
  setDbaName(state, value) {
       this.state.requiredForm.bizInfo.dbaName = value;
  },
  setAddress1(state, value) {
       this.state.requiredForm.bizInfo.address1 = value;
  },
  setAddress2(state, value) {
       this.state.requiredForm.bizInfo.address2 = value;
  },
  setCity(state, value) {
       this.state.requiredForm.bizInfo.city = value;
  },
  setState(state, value) {
       this.state.requiredForm.bizInfo.state = value;
  },
  setZipcode(state, value) {
       this.state.requiredForm.bizInfo.zipcode = value;
  },
  setTaxId(state, value) {
       this.state.requiredForm.bizInfo.taxId = value;
  },
  setDuns(state, value) {
       this.state.requiredForm.bizInfo.duns = value;
  },
  setBusinessContactNumber(state, value) {
       this.state.requiredForm.bizInfo.businessContactNumber = value;
  },
  setEmail(state, value) {
       this.state.requiredForm.bizInfo.email = value;
  },
  setWebsite(state, value) {
       this.state.requiredForm.bizInfo.website = value;
  },
  setEntityType(state, value) {
       this.state.requiredForm.bizInfo.entityType = value;
  },
  setIndustryType(state, value) {
       this.state.requiredForm.bizInfo.industryType = value;
  },
  setYrsInBusiness(state, value) {
       this.state.requiredForm.bizInfo.yrsInBusiness = value;
  },
  setGender(state, value) {
       this.state.requiredForm.ownerInfo.gender = value;
  },
  setIndictment(state, value) {
       this.state.requiredForm.ownerInfo.indictment = value;
  },
  setArrested(state, value) {
       this.state.requiredForm.ownerInfo.arrested = value;
  },
  setConvicted(state, value) {
       this.state.requiredForm.ownerInfo.convicted = value;
  },
  setEthnicity(state, value) {
       this.state.requiredForm.ownerInfo.ethnicity = value;
  },
  setRace(state, value) {
       this.state.requiredForm.ownerInfo.race = value;
  },
  setVeteran(state, value) {
       this.state.requiredForm.ownerInfo.veteran = value;
  },
  setFirstName(state, value) {
       this.state.requiredForm.ownerInfo.firstName = value;
  },
  setMiddleName(state, value) {
       this.state.requiredForm.ownerInfo.middleName = value;
  },
  setLastName(state, value) {
       this.state.requiredForm.ownerInfo.lastName = value;
  },
  setOwnerInfoAddress1(state, value) {
       this.state.requiredForm.ownerInfo.ownerInfoAddress1 = value;
  },
  setOwnerInfoAddress2(state, value) {
       this.state.requiredForm.ownerInfo.ownerInfoAddress2 = value;
  },
  setOwnerInfoCity(state, value) {
       this.state.requiredForm.ownerInfo.ownerInfoCity = value;
  },
  setOwnerInfoState(state, value) {
       this.state.requiredForm.ownerInfo.ownerInfoState = value;
  },
  setOwnerInfoZipcode(state, value) {
       this.state.requiredForm.ownerInfo.ownerInfoZipcode = value;
  },
  setDob(state, value) {
       this.state.requiredForm.ownerInfo.dob = value;
  },
  setSsn(state, value) {
       this.state.requiredForm.ownerInfo.ssn = value;
  },
  setCitizen(state, value) {
       this.state.requiredForm.ownerInfo.citizen = value;
  },
  setOwnerInfoEmail(state, value) {
       this.state.requiredForm.ownerInfo.ownerInfoEmail = value;
  },
  setPhone(state, value) {
       this.state.requiredForm.ownerInfo.phone = value;
  },
  setSuspendFederalAgency(state, value) {
    this.state.requiredForm.ownerInfo.suspendFederalAgency = value;
  },
  setChildSupport(state, value) {
    this.state.requiredForm.ownerInfo.childSupport = value;
  },
  setCreditScore(state, value) {
       this.state.requiredForm.ownerInfo.creditScore = value;
  },
  setOtherIncome(state, value) {
       this.state.requiredForm.contingentLiabilities.otherIncome = value;
  },
  setLegalClaimsFederal(state, value) {
       this.state.requiredForm.contingentLiabilities.legalClaimsFederal = value;
  },
  setIncomeTax(state, value) {
       this.state.requiredForm.contingentLiabilities.incomeTax = value;
  },
  setOtherSpecialDebt(state, value) {
       this.state.requiredForm.contingentLiabilities.otherSpecialDebt = value;
  },
  setSalary(state, value) {
       this.state.requiredForm.sourceIncome.salary = value;
  },
  setNetInvestIncome(state, value) {
       this.state.requiredForm.sourceIncome.netInvestIncome = value;
  },
  setRealEstateIncome(state, value) {
       this.state.requiredForm.sourceIncome.realEstateIncome = value;
  },
  setLifeInsuranceLoan(state, value) {
       this.state.requiredForm.sourceIncome.lifeInsuranceLoan = value;
  },
  setTotalNetIncome(state, value) {
       this.state.requiredForm.sourceIncome.totalNetIncome = value;
  },
  setRequiredFormStateObj(state, value) {
       this.state.requiredForm = value;
  }
}



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
<template>
  <div class="intro-y box py-10 sm:py-20 mt-5">
    <div class="wizard flex flex-col lg:flex-row justify-center px-5 sm:px-20">
      <div
        class="intro-x lg:text-center flex items-center lg:block flex-1 z-10"
        :class="{ active: step == i }"
        v-for="(stepItem, i) in steps"
        :key="i"
      >

        <button
          :class="
            step == i
              ? 'btn-primary'
              : 'ext-gray-600 bg-gray-200 dark:bg-dark-1'
          "
          class="w-10 h-10 rounded-full btn"
        >
      {{ i + 1 }}
        </button>
        <div class="lg:w-32 font-medium text-base lg:mt-3 ml-3 lg:mx-auto">
          {{ stepItem.name }}
        </div>
      </div>
      <div class="wizard__line hidden lg:block w-2/3 bg-gray-200 dark:bg-dark-1 absolute mt-5"></div>
    </div>
    <div>
      <div v-if="!fatalError">
        <transition :name="effect" mode="out-in">
          <keep-alive>
            <component
              :store="store"
              :step="step"
              :setState="setState"
              ref="step"
              :is="stepComponent"
              @loading="loadingAction"
              @error="errorHandler"
              @fatal-error="blockStepper"
              @can-continue="nextStepAction"
              @set-step="setStep"
            />
          </keep-alive>
        </transition>
      </div>
      <div v-else>{{ fatalErrorMsg }}</div>
    </div>
    <div class="px-5 sm:px-20">
    <div
      class="
        intro-y
        col-span-12
        flex
        items-center
        justify-center
        sm:justify-end
        mt-5
      "
    >
      <button class="btn btn-secondary w-24" v-if="step > 0"  @click="backStep"  >Previous</button>
      <button class="btn btn-primary w-24 ml-2" v-if="step < steps.length - 1"  @click="nextStep">Next</button>
      <button class="btn btn-success w-24 ml-2" v-if="steps[step].confirm" @click="$emit('confirm')">{{ steps[step].confirm }}</button>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DStepper",
  props: {
    steps: { type: Array, default: () => [] },
    initialState: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      store: {
        state: this.initialState,
        setState: this.setState,
        resetState: this.resetState
      },
      step: 0,
      loading: false,
      error: false,
      fatalError: false,
      fatalErrorMsg: "",
      effect: "in-out-translate-fade",
      shake: false
    }
  },
  computed: {
    activeStep() {
      return this.steps[this.step]
    },
    stepComponent() {
      return this.steps[this.step].component
    },
    iconClasses() {
      if (!this.activeStep.icon) return ""
      else if (/\s/.test(this.activeStep.icon)) return this.activeStep.icon
      return `fas ${this.activeStep.icon}`
    }
  },
  methods: {
    setStep(step) {
      if (step >= 1 && step <= this.steps.length) this.step = step - 1
    },
    resetState() {
      this.store.state = {
        ...this.initialState
      }
    },
    setState(key, value) {
      this.store.state = {
        ...this.store.state,
        [key]: value
      }
    },
    errorHandler(payload) {
      this.error = payload
      this.shake = payload
      setTimeout(() => {
        this.shake = !payload
      }, 750)
    },
    blockStepper(msg) {
      this.resetParams()
      this.fatalErrorMsg = msg
      this.fatalError = true
    },
    resetParams() {
      this.error = false
      this.loading = false
      this.fatalErrorMsg = ""
      this.fatalError = false
    },
    stepNumberClasses(i) {
      return {
        "bg-primary text-white": this.step === i && !this.fatalError,
        "bg-success text-white": this.step > i && !this.fatalerror,
        "bg-danger text-white": this.fatalError && this.step === i,
        "text-primary": this.step < i
      }
    },
    nextStep() {
      if (!this.$refs.step.proceed) {} else {
        if (!this.$refs.step.proceed()) {
          return
        }

      }




      if (!this.$refs.step.commitStore) {
        this.$emit('nextStepChange');
        return this.nextStepAction()
      } else {
        this.$refs.step.commitStore().then((res) => {
          this.$emit('nextStepChange');
          this.nextStepAction()

        }) .catch((err) => {
          console.log(err);
          this.$refs.step.setErrors(err);
        });

      }
    },
    nextStepAction() {
      this.effect = "in-out-translate-fade"
      this.resetParams()
      if (this.step < this.steps.length - 1) this.step++
    },
    backStep() {
      this.effect = "out-in-translate-fade"
      this.resetParams()
      if (this.step > 0) this.step--
    },
    loadingAction(status) {
      this.loading = status
      //if (!status) this.nextStepAction();
    }
  }
}
</script>

<style>
.d-stepper .d-stepper-header {
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}

.d-stepper .d-stepper-header::before {
  position: absolute;
  width: 100%;
  height: 1px;
  background: #ddd;
  top: 20px;
  left: 0;
  content: " ";
}

.d-stepper .step-number {
  background: #e9e9e9;
  border-radius: 50%;
  text-align: center;
  height: 40px;
  width: 40px;
  display: flex;
}
.d-stepper .step-number-content {
  transition: transform 0.2s;
  z-index: 2;
  width: 68px;
}

.d-stepper .step-number-content div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.d-stepper .step-number-content.active {
  transform: scale(1.25);
}

.in-out-translate-fade-enter-active,
.in-out-translate-fade-leave-active {
  transition: all 0.15s;
}
.in-out-translate-fade-enter,
.in-out-translate-fade-leave-active {
  opacity: 0;
}
.in-out-translate-fade-enter {
  transform: translateX(100px);
}
.in-out-translate-fade-leave-active {
  transform: translateX(-100px);
}

.out-in-translate-fade-enter-active,
.out-in-translate-fade-leave-active {
  transition: all 0.15s;
}
.out-in-translate-fade-enter,
.out-in-translate-fade-leave-active {
  opacity: 0;
}
.out-in-translate-fade-enter {
  transform: translateX(-100px);
}
.out-in-translate-fade-leave-active {
  transform: translateX(100px);
}
</style>

<template>
  <div class="
      px-5
      sm:px-20
      mt-10
      pt-10
      border-t border-gray-200
      dark:border-dark-5
    ">
    <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
      <div class="intro-y col-span-12 sm:col-span-4">
        <label for="input-wizard-1" class="form-label">{{$t('message.ownerInformationFirstName')}} *</label>
        <input id="input-wizard-1" type="text" class="form-control" placeholder="First Name" v-model='firstName'/>
        <template v-if="errorValidation.firstName !== ''">
          <small class="text-theme-21 mt-2">
            {{ errorValidation.firstName }} <br>
          </small>
        </template>
      </div>
      <div class="intro-y col-span-12 sm:col-span-4">
        <label for="input-wizard-2"
               class="form-label">{{$t('message.ownerInformationMiddleName')
          }}</label><input id="input-wizard-2" type="text"
                                                                                 class="form-control"
                                                                                 placeholder="Middle Name"
                                                                                 v-model='middleName'/>
        <template v-if="errorValidation.middleName !== ''">
          <small class="text-theme-21 mt-2">
            {{ errorValidation.middleName }} * <br>
          </small>
        </template>
      </div>
      <div class="intro-y col-span-12 sm:col-span-4">
        <label for="input-wizard-3"
               class="form-label">{{$t('message.ownerInformationLastName')
          }}*</label><input id="input-wizard-3" type="text"
                                                                                class="form-control"
                                                                                placeholder="Last Name"
                                                                                v-model='lastName'/>
        <template v-if="errorValidation.lastName !== ''">
          <small class="text-theme-21 mt-2">
            {{ errorValidation.lastName }} <br>
          </small>
        </template>
      </div>
      <div class="intro-y col-span-12 sm:col-span-6">
        <label for="input-wizard-4"
               class="form-label">{{$t('message.ownerInformationBirthDate')
          }}*</label>

        <div id="input-group-datepicker">
          <div class="relative w-80">
            <div
              class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
            >
              <CalendarIcon class="w-4 h-4"/>
            </div>
            <Litepicker
              v-model="dob"
              :options="{
              format: 'MM-DD-YYYY',
              autoApply: true,
              showWeekNumbers: false,
              dropdowns: {
                minYear: 1930,
                maxYear: null,
                months: true,
                years: true
              }
            }"
              class="form-control pl-12"
            />
          </div>
        </div>
        <!-- <input id="input-wizard-4" type="text" class="form-control"  v-mask="'##/##/####'" placeholder="Date of Birth" v-model='dob' /> -->
        <template v-if="errorValidation.dob !== ''">
          <small class="text-theme-21 mt-2">
            {{ errorValidation.dob }} <br>
          </small>
        </template>
      </div>


      <div class="intro-y col-span-12 sm:col-span-6">
        <label for="input-wizard-5"
               class="form-label">{{$t('message.ownerInformationSS') }}*</label>
        <input id="input-wizard-5" type="text" class="form-control" v-mask="'###-##-####'" placeholder="XXX-XX-XXXX"
               v-model='ssn'/>
        <template v-if="errorValidation.ssn !== ''">
          <small class="text-theme-21 mt-2">
            {{ errorValidation.ssn }} <br>
          </small>
        </template>
      </div>


      <div class="intro-y col-span-12 sm:col-span-12">
        <label for="input-wizard-5"
               class="form-label">{{$t('message.ownerInformationAddress1')
          }}*</label><input id="input-wizard-5" type="text"
                                                                                     class="form-control"
                                                                                     placeholder="1 Main St"
                                                                                     v-model='ownerInfoAddress1'/>
        <template v-if="errorValidation.ownerInfoAddress1 !== ''">
          <small class="text-theme-21 mt-2">
            {{ errorValidation.ownerInfoAddress1 }} <br>
          </small>
        </template>
      </div>
      <div class="intro-y col-span-12 sm:col-span-12">
        <label for="input-wizard-6"
               class="form-label">{{$t('message.ownerInformationAddress2')
          }}</label><input id="input-wizard-6" type="text"
                                                                                    class="form-control"
                                                                                    placeholder="Suite 100 Bld 200"
                                                                                    v-model='ownerInfoAddress2'/>
        <template v-if="errorValidation.ownerInfoAddress2 !== ''">
          <small class="text-theme-21 mt-2">
            {{ errorValidation.ownerInfoAddress2 }} <br>
          </small>
        </template>
      </div>
      <div class="intro-y col-span-12 sm:col-span-7">
        <label for="input-wizard-7"
               class="form-label">{{$t('message.ownerInformationCity')
          }}</label><input id="input-wizard-7" type="text"
                                                                          class="form-control" placeholder="City"
                                                                          v-model='ownerInfoCity'/>
        <template v-if="errorValidation.ownerInfoCity !== ''">
          <small class="text-theme-21 mt-2">
            {{ errorValidation.ownerInfoCity }} <br>
          </small>
        </template>
      </div>
      <div class="intro-y col-span-12 sm:col-span-3">
        <label for="input-wizard-12" class="form-label">Select
          {{$t('message.ownerInformationState') }}</label>
        <select id="input-wizard-12" class="form-select" v-model="ownerInfoState">
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District Of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
        </select>
        <template v-if="errorValidation.ownerInfoState !== ''">
          <small class="text-theme-21 mt-2">
            {{ errorValidation.ownerInfoState }} <br>
          </small>
        </template>
      </div>
      <div class="intro-y col-span-12 sm:col-span-2">
        <label for="input-wizard-9"
               class="form-label">{{$t('message.ownerInformationZipCode')
          }}</label>
        <input id="input-wizard-9" type="text" class="form-control" v-mask="'#####'" placeholder="Zip Code"
               v-model='ownerInfoZipcode'/>
        <template v-if="errorValidation.ownerInfoZipcode !== ''">
          <small class="text-theme-21 mt-2">
            {{ errorValidation.ownerInfoZipcode }} <br>
          </small>
        </template>
      </div>


      <div class="intro-y col-span-12 sm:col-span-6">
        <label for="input-wizard-50"
               class="form-label">{{$t('message.ownerInformationPhone')
          }}</label>
        <input id="input-wizard-50" type="tel" class="form-control" v-mask="'(###) ###-####'"
               placeholder="(XXX) XXX-XXXX" v-model="phone"/>
        <template v-if="errorValidation.phone !== ''">
          <small class="text-theme-21 mt-2">
            {{ errorValidation.phone }} <br>
          </small>
        </template>
      </div>
      <div class="intro-y col-span-12 sm:col-span-6">
        <label for="input-wizard-11"
               class="form-label">{{$t('message.ownerInformationEmail')}}*</label><input id="input-wizard-11" type="text"
                                                                             class="form-control" placeholder="Email"
                                                                             v-model="ownerInfoEmail"/>
        <template v-if="errorValidation.ownerInfoEmail !== ''">
          <small class="text-theme-21 mt-2">
            {{ errorValidation.ownerInfoEmail }} <br>
          </small>
        </template>
      </div>
      <div class="intro-y col-span-12 sm:col-span-6">
        <label for="input-wizard-10" class="form-label">{{$t('message.ownerInformationCreditScore')}}</label>
        <input id="input-wizard-10" type="text" class="form-control" v-model='creditScore'/>
        <template v-if="errorValidation.creditScore !== ''">
          <small class="text-theme-21 mt-2">
            {{ errorValidation.creditScore }} <br>
          </small>
        </template>
      </div>
      <div class="intro-y col-span-12 sm:col-span-6">
        <label for="input-wizard-12" class="form-label">Select {{$t('message.ownerInformationGender')}}</label><select id="input-wizard-12"
                                                                                     class="form-select"
                                                                                     v-model="gender">
        <option>Female</option>
        <option>Male</option>
        <option selected>Not Disclosed</option>
      </select>
      </div>
      <template v-if="errorValidation.gender !== ''">
        <small class="text-theme-21 mt-2">
          {{ errorValidation.gender }} <br>
        </small>
      </template>


      <div class="intro-y col-span-4 sm:col-span-4">
        <label for="input-wizard-12" class="form-label">Select {{$t('message.ownerInformationEthnicity')}}</label><select id="input-wizard-12"
                                                                                        class="form-select"
                                                                                        v-model="ethnicity">
        <option>Hispanic or Latino</option>
        <option>Not Hispanic or Latino</option>
        <option selected>Not Disclosed</option>
      </select>
      </div>
      <template v-if="errorValidation.ethnicity !== ''">
        <small class="text-theme-21 mt-2">
          {{ errorValidation.ethnicity }} <br>
        </small>
      </template>


      <div class="intro-y col-span-4 sm:col-span-4">
        <label for="input-wizard-12" class="form-label">Select {{$t('message.ownerInformationRace')}}</label><select id="input-wizard-12"
                                                                                   class="form-select" v-model="race">
        <option>American Indian or Alaska Native</option>
        <option>Asian</option>
        <option>Black or African American</option>
        <option>Native Hawaiian or Pacific Islander</option>
        <option>White</option>
        <option selected>Not Disclosed</option>
      </select>
      </div>
      <template v-if="errorValidation.race !== ''">
        <small class="text-theme-21 mt-2">
          {{ errorValidation.race }} <br>
        </small>
      </template>


      <div class="intro-y col-span-4 sm:col-span-4">
        <label for="input-wizard-12" class="form-label">Select {{$t('message.ownerInformationVeteran')}}</label><select id="input-wizard-12"
                                                                                      class="form-select"
                                                                                      v-model="veteran">
        <option>Non-Veteran</option>
        <option>Veteran</option>
        <option>Service Disable Veteran</option>
        <option>Spouse of Veteran</option>
        <option selected>Not Disclosed</option>
      </select>
      </div>
      <template v-if="errorValidation.veteran !== ''">
        <small class="text-theme-21 mt-2">
          {{ errorValidation.veteran }} <br>
        </small>
      </template>


      <div class="intro-y col-span-12 sm:col-span-6">
        <label>{{$t('message.ownerInformationQuestion1')}}</label>
        <div class="form-check mt-2">
          <input id="radio-switch-11" class="form-check-input" type="radio" value="true" name="childSupport-choice"
                 v-model='childSupport'/>
          <label class="form-check-label" for="radio-switch-11">Yes</label>
        </div>
        <div class="form-check mt-2">
          <input id="radio-switch-21" class="form-check-input" type="radio" value="false" name="childSupport-choice"
                 v-model='childSupport'/>
          <label class="form-check-label" for="radio-switch-21">No</label>
        </div>
      </div>
      <div class="intro-y col-span-12 sm:col-span-6">
        <label>{{$t('message.ownerInformationQuestion2')}}*</label>
        <div class="form-check mt-2">
          <input id="radio-switch-12" class="form-check-input" type="radio" value=true
                 name="suspendFederalAgency-choice" v-model='suspendFederalAgency'/>
          <label class="form-check-label" for="radio-switch-12">Yes</label>
        </div>
        <div class="form-check mt-2">
          <input id="radio-switch-22" class="form-check-input" type="radio" value=false
                 name="suspendFederalAgency-choice" v-model='suspendFederalAgency'/>
          <label class="form-check-label" for="radio-switch-22">No</label>
        </div>
      </div>


      <div class="intro-y col-span-12 sm:col-span-6">
        <label>{{$t('message.ownerInformationQuestion3')}}*</label>
        <div class="form-check mt-2">
          <input id="radio-switch-1" class="form-check-input" type="radio" value="true" name="indictment-choice"
                 v-model='indictment'/>
          <label class="form-check-label" for="radio-switch-1">Yes</label>
        </div>
        <div class="form-check mt-2">
          <input id="radio-switch-2" class="form-check-input" type="radio" value="false" name="indictment-choice"
                 v-model='indictment'/>
          <label class="form-check-label" for="radio-switch-2">No</label>
        </div>
      </div>

      <div class="intro-y col-span-12 sm:col-span-6">
        <label>{{$t('message.ownerInformationQuestion4')}}*</label>
        <div class="form-check mt-2">
          <input id="radio-switch-1" class="form-check-input" type="radio" value="true" name="arrested-choice"
                 v-model='arrested'/>
          <label class="form-check-label" for="radio-switch-1">Yes</label>
        </div>
        <div class="form-check mt-2">
          <input id="radio-switch-2" class="form-check-input" type="radio" value="false" name="arrested-choice"
                 v-model='arrested'/>
          <label class="form-check-label" for="radio-switch-2">No</label>
        </div>
      </div>

      <div class="intro-y col-span-12 sm:col-span-6">
        <label>{{$t('message.ownerInformationQuestion5')}}*</label>
        <div class="form-check mt-2">
          <input id="radio-switch-1" class="form-check-input" type="radio" value="true" name="convicted-choice"
                 v-model='convicted'/>
          <label class="form-check-label" for="radio-switch-1">Yes</label>
        </div>
        <div class="form-check mt-2">
          <input id="radio-switch-2" class="form-check-input" type="radio" value="false" name="convicted-choice"
                 v-model='convicted'/>
          <label class="form-check-label" for="radio-switch-2">No</label>
        </div>
      </div>

      <div class="intro-y col-span-12 sm:col-span-6">
        <label>{{$t('message.ownerInformationQuestion6')}}*</label>
        <div class="form-check mt-2">
          <input id="radio-switch-1" class="form-check-input" type="radio" value="true" name="citizen-choice"
                 v-model='citizen'/>
          <label class="form-check-label" for="radio-switch-1">Yes</label>
        </div>
        <div class="form-check mt-2">
          <input id="radio-switch-2" class="form-check-input" type="radio" value="false" name="citizen-choice"
                 v-model='citizen'/>
          <label class="form-check-label" for="radio-switch-2">No</label>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import {
  computed,
  reactive
} from 'vue'
import {
  useStore
} from 'vuex'
import {
  object,
  string,
  number,
  bool
} from "yup";
import * as yup from 'yup';
import {mask} from 'vue-the-mask';


export default {
  directives: {mask},
  setup() {
    // const { errorMessage, firstName } = useField('firstName', yup.string().required().min(8));

    const errorValidation = reactive({
      firstName: '',
      middleName: '',
      lastName: '',
      dob: '',
      ssn: '',
      ownerInfoAddress1: '',
      ownerInfoAddress2: '',
      ownerInfoCity: '',
      ownerInfoState: '',
      ownerInfoZipcode: '',
      phone: '',
      ownerInfoEmail: '',
      gender: '',
      race: '',
      ethnicity: '',
      veteran: '',
      indictment: '',
      arrested: '',
      convicted: '',
      citizen: '',
      suspendFederalAgency: '',
      childSupport: '',
      creditScore: ''
    });


    const phoneRegExp = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
    // const dateRegExp = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
    const ssnRegExp = /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/


    const ownerSchema = object().shape({
      // creditScore: number().min(300, 'Credit score number must be greater than or equal to 300').max(800, 'Credit score number must be less than or equal to 800').required('Credit score number must be between 300-800'),
      firstName: string().required('First name is a required field'),
      // middleName: string().required('Middle name is a required field'),
      lastName: string().required('Last name is a required field'),
      ssn: string().required('Social security is a required field').matches(ssnRegExp, "SSN format is invalid"),
      // dob: string().required('Date of birth is required').matches(dateRegExp, "Date format is invalid"),
      ownerInfoAddress1: string().required('Address 2 name is a required field'),
      ownerInfoCity: string().required('City is a required field'),
      ownerInfoState: string().required('State is a required field'),
      ownerInfoZipcode: string().required('Zipcode is a required field').matches(/^[0-9]+$/, "Must be only digits").min(5, 'Must be exactly 5 digits').max(5, 'Must be exactly 5 digits'),
      phone: string().required('Phone is a required field').matches(phoneRegExp, 'Phone number is not valid'),
      ownerInfoEmail: string().required('Email is a required field').email('Email is not in an email format'),
    });


    const convertFirstLetterUpper = (str) => {
      return str.charAt(0).toUpperCase() + str.substring(1, str.length)
    }

    const validate = async (field, value) => {

      console.log('in validate')

      let obj = {};
      obj[field] = value;

      try {
        await ownerSchema
          .validateAt(field, obj).then(() => {
            eval("errorValidation." + field + " = ''");
            console.log("Validate: requiredForm/set" + convertFirstLetterUpper(field));
            console.log("Validate: value:" + value);
            console.log("Validate: field:" + field);
            store.commit("requiredForm/set" + convertFirstLetterUpper(field), value);
          }).catch(err => {
            eval("errorValidation." + field + " = '" + err.message + "'");
            store.commit("requiredForm/set" + convertFirstLetterUpper(field), value);
          });
      } catch (error) {
        console.log("Error:" + error);
        store.commit("requiredForm/set" + convertFirstLetterUpper(field), value);
      }


    }


    function parseBool(val) {
      return (String(val).toLowerCase() === 'true');
    }


    const commitStore = async function () {
      await ownerSchema.validate(store.state.requiredForm.ownerInfo, {
        abortEarly: false
      });
    }


    const setErrors = function (e) {
      for (var i in e.inner) {
        eval("errorValidation." + e.inner[i].path + " = '" + e.inner[i].errors + "'");
      }
    }

    const store = useStore()


    const firstName = computed({
      get() {
        return store.state.requiredForm.ownerInfo.firstName;
      },
      set(value) {
        validate('firstName', value);
      }
    });

    const middleName = computed({
      get() {
        return store.state.requiredForm.ownerInfo.middleName;
      },
      set(value) {
        validate('middleName', value);
      }
    });

    const lastName = computed({
      get() {
        return store.state.requiredForm.ownerInfo.lastName;
      },
      set(value) {
        validate('lastName', value);
      }
    });

    const dob = computed({
      get() {
        return store.state.requiredForm.ownerInfo.dob;
      },
      set(value) {
        store.commit('requiredForm/setDob', value)
      }
    });

    const ssn = computed({
      get() {
        return store.state.requiredForm.ownerInfo.ssn;
      },
      set(value) {
        validate('ssn', value);
      }
    });


    const ownerInfoAddress1 = computed({
      get() {
        return store.state.requiredForm.ownerInfo.ownerInfoAddress1;
      },
      set(value) {
        validate('ownerInfoAddress1', value);
      }
    });

    const ownerInfoAddress2 = computed({
      get() {
        return store.state.requiredForm.ownerInfo.ownerInfoAddress2;
      },
      set(value) {

      }
    });

    const ownerInfoCity = computed({
      get() {
        return store.state.requiredForm.ownerInfo.ownerInfoCity;
      },
      set(value) {
        validate('ownerInfoCity', value);
      }
    });

    const ownerInfoState = computed({
      get() {
        return store.state.requiredForm.ownerInfo.ownerInfoState;
      },
      set(value) {
        validate('ownerInfoState', value);
      }
    });

    const ownerInfoZipcode = computed({
      get() {
        return store.state.requiredForm.ownerInfo.ownerInfoZipcode;
      },
      set(value) {
        validate('ownerInfoZipcode', value);
      }
    });
    const ownerInfoEmail = computed({
      get() {
        return store.state.requiredForm.ownerInfo.ownerInfoEmail;
      },
      set(value) {
        validate('ownerInfoEmail', value);
      }
    });

    const phone = computed({
      get() {
        return store.state.requiredForm.ownerInfo.phone;
      },
      set(value) {
        console.log("SET ownerInfoState:");
        validate('phone', value);
      }
    });

    const creditScore = computed({
      get() {
        console.log("GET ownerInfoState:");
        return store.state.requiredForm.ownerInfo.creditScore;
      },
      set(value) {
        console.log("SET ownerInfoState:");
        store.commit('requiredForm/setCreditScore', value)
      }
    });


    const gender = computed({
      get() {
        return store.state.requiredForm.ownerInfo.gender;
      },
      set(value) {
        validate('gender', value);
      }
    });
    const race = computed({
      get() {
        return store.state.requiredForm.ownerInfo.race;
      },
      set(value) {
        validate('race', value);
      }
    });
    const ethnicity = computed({
      get() {
        return store.state.requiredForm.ownerInfo.ethnicity;
      },
      set(value) {
        validate('ethnicity', value);
      }
    });
    const veteran = computed({
      get() {
        return store.state.requiredForm.ownerInfo.veteran;
      },
      set(value) {
        validate('veteran', value);
      }
    });
    const indictment = computed({
      get() {
        return store.state.requiredForm.ownerInfo.indictment;
      },
      set(value) {
        validate('indictment', parseBool(value));
      }
    });
    const arrested = computed({
      get() {
        return store.state.requiredForm.ownerInfo.arrested;
      },
      set(value) {
        validate('arrested', parseBool(value));
      }
    });
    const convicted = computed({
      get() {
        return store.state.requiredForm.ownerInfo.convicted;
      },
      set(value) {
        validate('convicted', parseBool(value));
      }
    });
    const citizen = computed({
      get() {
        return store.state.requiredForm.ownerInfo.citizen;
      },
      set(value) {
        validate('citizen', parseBool(value));
      }
    });

    const suspendFederalAgency = computed({
      get() {
        return store.state.requiredForm.ownerInfo.suspendFederalAgency;
      },
      set(value) {
          validate('suspendFederalAgency', parseBool(value));
      }
    });

    const childSupport = computed({
      get() {
        return store.state.requiredForm.ownerInfo.childSupport;
      },
      set(value) {
        validate('childSupport', parseBool(value));
      }
    });

    return {
      // access a state in computed function
      firstName,
      middleName,
      lastName,
      dob,
      ssn,
      suspendFederalAgency,
      childSupport,
      ownerInfoAddress1,
      ownerInfoAddress2,
      ownerInfoCity,
      ownerInfoState,
      ownerInfoZipcode,
      phone,
      creditScore,
      ownerInfoEmail,
      gender,
      indictment,
      arrested,
      convicted,
      citizen,
      ethnicity,
      race,
      veteran,
      store,
      commitStore,
      errorValidation,
      setErrors
    }
  }
}
</script>

<style>
</style>
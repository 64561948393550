<template>
  <div
    class="
      px-5
      sm:px-20
      mt-10
      pt-10
      border-t border-gray-200
      dark:border-dark-5
    "
  >
    <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
      <div class="intro-y col-span-3 sm:col-span-3">
        <label for="input-wizard-1" class="form-label">{{$t('message.sourceOfIncomeSalary')}}*</label>
         <CurrencyInput id="input-wizard-1"  class="form-control" v-model="salary" :options="opt" />
      </div>
      <div class="intro-y col-span-3 sm:col-span-3">
        <label for="input-wizard-2" class="form-label">{{$t('message.sourceOfIncomeNetInvestmentIncome')}}*</label>         <CurrencyInput id="input-wizard-2" class="form-control"  v-model="netInvestIncome" :options="opt" />
      </div>
      <div class="intro-y col-span-3 sm:col-span-3">
        <label for="input-wizard-3" class="form-label">{{$t('message.sourceOfIncomeRealEstateIncome')}}*</label>         <CurrencyInput id="input-wizard-3" class="form-control" v-model="realEstateIncome" :options="opt" />
      </div>
      <div class="intro-y col-span-3 sm:col-span-3">
        <label for="input-wizard-4" class="form-label">{{$t('message.sourceOfIncomeLifeInsuranceLoanAgainstLifeInsurance')}}*</label>
        <CurrencyInput id="input-wizard-4" class="form-control"  v-model="lifeInsuranceLoan" :options="opt" />
      </div>
      <div class="intro-y col-span-3 sm:col-span-3">
        <label for="input-wizard-52" class="form-label">{{$t('message.sourceOfIncomeTotalNetIncome')}}*</label>
        <CurrencyInput id="input-wizard-52" class="form-control"  v-model="totalNetIncome" :options="opt" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from '@/store'
import CurrencyInput from "@/components/currency-input/Main.vue";


export default {
  components: {
   CurrencyInput,
 },
  setup () {
    const store = useStore()

        const opt = {currency: 'USD',currencyDisplay: 'symbol',hideCurrencySymbolOnFocus: false,  hideGroupingSeparatorOnFocus: false, precision: 0 }
        const salary = computed({
            get() { return store.state.requiredForm.sourceIncome.salary; },
            set(value) { store.commit("requiredForm/setSalary", value); }
          });

        const netInvestIncome = computed({
            get() { return store.state.requiredForm.sourceIncome.netInvestIncome; },
            set(value) { store.commit("requiredForm/setNetInvestIncome", value); }
          });

        const realEstateIncome = computed({
            get() { return store.state.requiredForm.sourceIncome.realEstateIncome; },
            set(value) { store.commit("requiredForm/setRealEstateIncome", value); }
          });

        const lifeInsuranceLoan = computed({
              get() { return store.state.requiredForm.sourceIncome.lifeInsuranceLoan; },
              set(value) { store.commit("requiredForm/setLifeInsuranceLoan", value); }
            });

        const totalNetIncome = computed({
              get() { return store.state.requiredForm.sourceIncome.totalNetIncome; },
              set(value) { store.commit("requiredForm/setTotalNetIncome", value); }
            });


    return {
      // access a state in computed function
      salary,
      netInvestIncome,
      realEstateIncome,
      lifeInsuranceLoan,
      totalNetIncome,
      store,
      opt,
    }
  }
}

</script>

<style>
</style>